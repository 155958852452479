import React, { useEffect } from 'react'
import { PatientPage, SEO } from "components";
import { scrollToElement } from 'libs/util.helper';
import { useWindowSize } from 'hooks';
import './sitemap.scss';

const pageDescription = "Browse the patient site map to find a full list of pages for the AVEED® (testosterone undecanoate) injection CIII site.";


const SitemapPage = () => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 768;
  useEffect(() => {
    //Fix for showing double ISI in small pages
    setTimeout(() => {
      scrollToElement('patient-sitemap-page-top', isMobile ? 75 : 120)
    }, 100)
  }, [])
  return (
    <PatientPage pageClassName="sitemap">
      <SEO pageTitle="Patient Site map" pageDescription={pageDescription} />
      <div className="dfa-container gutter-left gutter-right">
        <h1 id="patient-sitemap-page-top">Site Map</h1>
        <ul className="link-list">
          <li><a href="/">Patient Home</a></li>
          <li><a href="/what-is-hypogonadism/">About Hypogonadism</a></li>
          <li><a href="/why-aveed/">Why AVEED<sup>&reg;</sup>?</a></li>
          <li><a href="/what-to-expect/">What to Expect</a></li>
          <li><a href="/paying-for-aveed/">Paying for AVEED<sup>&reg;</sup></a></li>
          <li><a href="/resources/">Resources</a></li>
        </ul>
      </div>
    </PatientPage>

  )
}

export default SitemapPage
